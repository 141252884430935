import { DOCTOR_REPORTS_EXPORT } from "../../constant/ActionType";

export default function AllDoctorsExportRep(
  state = {
    DoctorrExportss: [],
  },
  action
) {
  switch (action.type) {
    case DOCTOR_REPORTS_EXPORT:
      return {
        ...state,
        DoctorrExportss: action?.data?.data,
      };

    default:
  }

  return state;
}
