import { REPORTS_ALL_EXPORT } from "../../constant/ActionType";

export default function ReportsExported(
  state = {
    exportedAllReports: [],
  },
  action
) {
  switch (action.type) {
    case REPORTS_ALL_EXPORT:
      return {
        ...state,
        exportedAllReports: action?.data?.data?.data,
      };

    default:
  }

  return state;
}
