import { PATIENT_REPORTS_EXPORT } from "../../constant/ActionType";

export default function AllPatientExportRep(
  state = {
    PatienttExportss: [],
  },
  action
) {
  switch (action.type) {
    case PATIENT_REPORTS_EXPORT:
      return {
        ...state,
        PatienttExportss: action?.data?.data,
      };

    default:
  }

  return state;
}
