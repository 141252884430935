// type

import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";

import icons from "helper/iconsHelper";

import {
  per_view_doctor_appointment,
  can_view_clients,
  per_symptoms_view,
} from "../helper/permissionsCheck";

// let permissionUser = localStorage.getItem("LoginData");
// let permissions = JSON.parse(permissionUser)?.permissions;

let permissionUser = localStorage.getItem("data");

let decryptedData = null;
if (permissionUser) {
  try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
      enc.Utf8
    );
    // ...
  } catch (error) {
    console.log(error, "er");
  }
}

const permissions = decryptedData
  ? JSON.parse(decryptedData)?.permissions
  : null;

const allowedPermissions =
  permissions && permissions.length !== 0
    ? permissions.map((permission) => permission?.permission)
    : [];

const permissionsAccessFunction = (brand) => {
  return (
    allowedPermissions &&
    allowedPermissions.length !== 0 &&
    allowedPermissions.some((permission) => permission.includes(brand))
  );
};
// ==============================|| MENU ITEMS - roles ||============================== //

// const flat = permissionsAccessFunction('company') ? {
const appointments = per_symptoms_view
  ? {
      id: "Symptoms",
      title: "Symptoms",
      icon: icons.symptoms,
      type: "group",
      children: [
        true
          ? {
              id: "all-symptoms",
              title: "View All Symptoms",
              type: "item",
              url: "/symptoms/all-symptoms",
              icon: icons.symptoms,
              breadcrumbs: true,
            }
          : null,
      ].filter(Boolean),
    }
  : null;
export default appointments;
