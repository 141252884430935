import { SlCalender } from "react-icons/sl";
import { FaUserDoctor } from "react-icons/fa6";
import { MdOutlinePayment } from "react-icons/md";
import { GiDuration } from "react-icons/gi";
import { HiUsers } from "react-icons/hi2";
import { MdAssignmentTurnedIn } from "react-icons/md";
import { BiCategory } from "react-icons/bi";
import { MdOutlineCastForEducation } from "react-icons/md";
import { GiWorld } from "react-icons/gi";
import { VscHistory } from "react-icons/vsc";
import { MdOutlineSick } from "react-icons/md";
import { LuShieldAlert } from "react-icons/lu";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { FaPrescriptionBottleMedical } from "react-icons/fa6";
import { ImInsertTemplate } from "react-icons/im";
import { MdAssignment } from "react-icons/md";


const icons = {
  calender: SlCalender,
  doctors: FaUserDoctor,
  payments: MdOutlinePayment,
  slots: GiDuration,
  users: HiUsers,
  roles: MdAssignmentTurnedIn,
  category: BiCategory,
  qualification: MdOutlineCastForEducation,
  country: GiWorld,
  history: VscHistory,
  patients: MdOutlineSick,
  symptoms: LuShieldAlert,
  report: HiOutlineDocumentReport,
  prescriptions: FaPrescriptionBottleMedical,
  template:ImInsertTemplate,
  // MdAssignment:Booking,
};

export default icons;
