import { STATICS_DASHBOARD_DATA } from "../../constant/ActionType";

export default function AllDataDashboardRed(
  state = {
    alldataDash: [],
  },
  action
) {
  switch (action.type) {
    case STATICS_DASHBOARD_DATA:
      return {
        ...state,
        alldataDash: action?.data?.data,
      };

    default:
  }

  return state;
}
