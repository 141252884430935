// ========================================= property management ===================================

export const CREATE_ALL_PROPERTY = "CREATE_ALL_PROPERTY";
export const GET_ALL_PROPERTY = "GET_ALL_PROPERTY";
export const GET_ALL_ACTIVE_TAX = "GET_ALL_ACTIVE_TAX";

export const GET_PROPERTY_PREMESIS = "GET_PROPERTY_PREMESIS";
export const GET_ALL_ACTIVE_PROPERTY = "GET_ALL_ACTIVE_PROPERTY";
export const UPDATE_PROPERTY = "UPDATE_PROPERTY";
export const UPDATE_PROPERTY_STATUS = "UPDATE_PROPERTY_STATUS";

export const GET_ALL_PROPERTY_TYPE = "GET_ALL_PROPERTY_TYPE";
export const GET_ALL_ACTIVE_PROPERTY_TYPE = "GET_ALL_ACTIVE_PROPERTY_TYPE";
export const CREATE_PROPERTY_TYPE = "CREATE_PROPERTY_TYPE";
export const UPDATE_PROPERTY_TYPE = "UPDATE_PROPERTY_TYPE";
export const UPDATE_PROPERTY_STATUS_TYPE = "UPDATE_PROPERTY_STATUS_TYPE";
export const GET_ALL_DASHBOARD = "GET_ALL_DASHBOARD";
export const GET_ALL_NOTIFICATION = "GET_ALL_NOTIFICATION";
export const PREMISES_FILTERS = "PREMISES_FILTERS";
export const TENANT_DELETE = "TENANT_DELETE";
export const PROPERTY_DLETE = "PROPERTY_DLETE";
export const PREMISES_DELETE = "PREMISES_DELETE";

export const GET_ALL_AMENITIES = "GET_ALL_AMENITIES";
export const GET_ALL_ACTIVE_AMENITIES = "GET_ALL_ACTIVE_AMENITIES";
export const CREATE_AMENITIES = "CREATE_AMENITIES";
export const UPDATE_AMENITIES = "UPDATE_AMENITIES";
export const UPDATE_AMENITIES_STATUS = "UPDATE_AMENITIES_STATUS";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

export const GET_ALL_FLAT_SIZE = "GET_ALL_FLAT_SIZE";
export const GET_ALL_ACTIVE_FLAT_SIZE = "GET_ALL_ACTIVE_FLAT_SIZE";
export const CREATE_FLAT_SIZE = "CREATE_FLAT_SIZE";
export const UPDATE_FLAT_SIZE = "UPDATE_FLAT_SIZE";
export const UPDATE_FLAT_SIZE_STATUS = "UPDATE_FLAT_SIZE_STATUS";

export const GET_ALL_FLAT = "GET_ALL_FLAT";
export const GET_ALL_ACTIVE_FLAT = "GET_ALL_ACTIVE_FLAT";
export const CREATE_FLAT = "CREATE_FLAT";
export const UPDATE_FLAT = "UPDATE_FLAT";
export const UPDATE_FLAT_STATUS = "UPDATE_FLAT_STATUS";

export const GET_ALL_TAXES = "GET_ALL_TAXES";
export const CREATE_TAXES = "CREATE_TAXES";
export const UPDATE_TAXES = "UPDATE_TAXES";
export const UPDATE_TAXES_STATUS = "UPDATE_TAXES_STATUS";
export const CLIENT_ALL_VIEW = "CLIENT_ALL_VIEW";
export const CLIENT_ALL_DETAILS = "CLIENT_ALL_DETAILS,";
export const CREATE_CLIENTS = "CREATE_CLIENTS";
export const UPDATE_CLIENTS = "UPDATE_CLIENTS";
export const CREATE_MAINTENANCE_TYPE = "CREATE_MAINTENANCE_TYPE";

export const GET_ALL_MANAGEMENT = "GET_ALL_MANAGEMENT";
export const UPDATE_MAINTENANCE_STATUS = "UPDATE_MAINTENANCE_STATUS";
export const CREATE_ADD_MAINTENANCE = "CREATE_ADD_MAINTENANCE";
export const UPDATE_EDIT_MAINTENANCE = "UPDATE_EDIT_MAINTENANCE";
export const UPDATE_MAINTENANCE_TYPE_STATUS = "UPDATE_MAINTENANCE_TYPE_STATUS";
export const EDIT_MAINTENANCE_TYPE_STATUS = "EDIT_MAINTENANCE_TYPE_STATUS";
export const ALL_MAINTENANCE = "ALL_MAINTENANCE";
export const ALL_ACTIVE_MAINTENANCE_TYPE = "ALL_ACTIVE_MAINTENANCE_TYPE";

export const ALL_ASSIGN_PERMISSIONS = "ALL_ASSIGN_PERMISSIONS";
export const ALL_ASSIGN_PREMISES_DETAILS = "ALL_ASSIGN_PERMISSIONS";
export const CREATE_ASSIGN_PERMISSION = "CREATE_ASSIGN_PERMISSION";

export const PAYMENT_UNIQUE_ID = "PAYMENT_UNIQUE_ID";
export const CREATE_PAYMENT = "CREATE_PAYMENT";
export const CREATE_CATEGORY = "CREATE_CATEGORY";

export const GET_PAYMENT = "GET_PAYMENT";

export const CREATE_ADD_USERS = "CREATE_ADD_USERS";
export const EDIT_UPDATE_USERS = "EDIT_UPDATE_USERS";
export const GET_ALL_USERS = "GET_ALL_USERS";

export const ALL_NOTIFICATION = "ALL_NOTIFICATION";

export const ALL_REPORTS = "ALL_REPORTS";
export const REPORT_BY_MONTH = "REPORT_BY_MONTH";
export const REPORT_BY_CLIENT = "REPORT_BY_CLIENT";
export const REPORT_BY_PAYMENT = "REPORT_BY_PAYMENT";

export const REPORTS_BY_PROPERTY = "REPORTS_BY_PROPERTY";
export const REPORTS_BY_FLATS_SHOPS = "REPORTS_BY_FLATS_SHOPS";
export const UPDATE_PATIENT_HISTORY = "UPDATE_PATIENT_HISTORY";
export const UPDATE_DOCTORS = "UPDATE_DOCTORS";
export const ACTIVE_DOCTORS_DEACTIVE = "ACTIVE_DOCTORS_DEACTIVE";
export const GET_ALL_PRESCRIPTION = "GET_ALL_PRESCRIPTION";
export const ASSISTED_APPOINTMENTS_BOOKING = "ASSISTED_APPOINTMENTS_BOOKING";
export const APPOITNMENT_DATETIME_SLOT = "APPOITNMENT_DATETIME_SLOT";

// ========================================= property management ===================================

export const FETCH_LOGIN_DATA = "FETCH_LOGIN_DATA";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const REGISTER_BRAND_OWNER = "REGISTER_BRAND_OWNER";
export const REGISTER_CATEGORY_STORE = "REGISTER_CATEGORY_STORE";
export const CREATE_ROLE = "CREATE_ROLE";
export const UPDATE_STATUS_CODE = "UPDATE_STATUS_CODE";
export const UPDATE_STATUS_USERS = "UPDATE_STATUS_USERS";
export const UPDATE_STATUS_BRAND = "UPDATE_STATUS_BRAND";
export const VIEW_USERS = "VIEW_USERS";
export const VIEW_PRODUCTS = "VIEW_PRODUCTS";
export const VIEW_ACTIVE_PRODUCTS = "VIEW_ACTIVE_PRODUCTS";
export const VIEW_ROLES = "VIEW_ROLES";
export const ASSIGN_PERMISSIONS = "ASSIGN_PERMISSIONS";
export const GETALL_PERMISSION = "GETALL_PERMISSION";
export const ROLE_PERMISSIONS = "ROLE_PERMISSIONS";
export const EDIT_USER = "EDIT_USER";
export const ALL_BRAND = "ALL_BRAND";
export const MAP_DATA = "MAP_DATA";
export const GETALL_CATEGEORY = "GETALL_CATEGEORY";
export const BRAND_ONE_DETAIL = "BRAND_ONE_DETAIL";
export const BRAND_EDIT = "BRAND_EDIT";
export const SUB_CATEGORY_SATUS_UPDATE = "SUB_CATEGORY_SATUS_UPDATE";
export const STATUS_UPDATE_CATEGORY = "STATUS_UPDATE_CATEGORY";
export const EDIT_SUB_CATEGORY = "EDIT_SUB_CATEGORY";
export const ALL_COMPANY = "ALL_COMPANY";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const ALL_BATCHES = "ALL_BATCHES";
export const ALL_BATCHES_COMPANY = "ALL_BATCHES_COMPANY";
export const COMPANY_DETAIL_ONE_USER = "COMPANY_DETAIL_ONE_USER";
export const All_COUNTS = "All_COUNTS";
export const All_RANDOM_CODE = "GET_ALL_RANDOM_CODE";
export const ALL_QRCODE_REPORT = "GET_ALL_QRCODE_REPORT";
export const EXPORT_CSV = "EXPORT_CSV";
export const EXPORT_CSV_COMPANY = "EXPORT_CSV_COMPANY";
export const EXPORT_CSV_REPORT_PROBLEM = "EXPORT_CSV_REPORT_PROBLEM";
export const EXPORT_CSV_CATEGORYIES = "EXPORT_CSV_CATEGORYIES";
export const EXPORT_CSV_RANDOM_CODE = "EXPORT_CSV_RANDOM_CODE";

// -----------------------------------------------------E-CLINIC-----------------------------------------------

export const GET_ALL_DOCTORS = "GET_ALL_DOCTORS";
export const CREATE_ALL_DOCTORS = "CREATE_ALL_DOCTORS";
export const EDIT_ALL_DOCTORS = "EDIT_ALL_DOCTORS";
export const DELETE_ALL_DOCTORS = "DELETE_ALL_DOCTORS";
export const ALL_DOCTORS_CATEGORY = "ALL_DOCTORS_CATEGORY";
export const CREATE_DOCTORS_CATEGORY = "CREATE_DOCTORS_CATEGORY";
export const EDIT_DOCTORS_CATEGORY = "EDIT_DOCTORS_CATEGORY";
export const CREATE_DOCTORS_QUALIFICATION = "CREATE_DOCTORS_QUALIFICATION";
export const GET_ALL_SYMPTOMS = "GET_ALL_SYMPTOMS";
export const CREATE_QUALIFICATION = "CREATE_QUALIFICATION";
export const CREATE_SYMPTOM = "CREATE_SYMPTOM";
export const EDIT_SYMPTOM = "EDIT_SYMPTOM";
export const EDIT_QUALIFICATION = "EDIT_QUALIFICATION";
export const GET_ALL_SLOTS = "GET_ALL_SLOTS";
export const DETAILS_DOCTORS = "DETAILS_DOCTORS";
export const GET_ALL_COUNTRY = "GET_ALL_COUNTRY";
export const UPDATE_APPOINTMENTS = "UPDATE_APPOINTMENTS";
export const ACTIVE_COUNTRY_DEACTIVE = "ACTIVE_COUNTRY_DEACTIVE";
// export const DOCTOR_DELETE = "DOCTOR_DELETE";
export const ALL_COUNTRY_CREATE = "ALL_COUNTRY_CREATE";
export const ALL_PATIENTS_DETAILS = "ALL_PATIENTS_DETAILS";
export const ALL_APPOINTMENTS_REPORTS = "ALL_APPOINTMENTS_REPORTS";
export const COUNTRY_EDIT = "COUNTRY_EDIT";
export const ALL_COUNTRY_DELETE = "ALL_COUNTRY_DELETE";
export const ALL_PATIENTS_DELETE = "ALL_PATIENTS_DELETE";
export const ALL_GET_REPORTS = "ALL_GET_REPORTS";

// -----------------------------------------------------E-CLINIC-----------------------------------------------

export const ALL_APPOINTMENTS = "ALL_APPOINTMENTS";
export const EDIT_SLOTS = "EDIT_SLOTS";
export const ALL_PATIENTS = "ALL_PATIENTS";
export const ALL_PATIENTS_HISTORY = "ALL_PATIENTS_HISTORY";
export const CREATE_PATIENTS_HISTORY = "CREATE_PATIENTS_HISTORY";
export const DELETE_QUALIFICATIONS = "DELETE_QUALIFICATIONS";
export const EDIT_PATIENT = "EDIT_PATIENT";

export const STATUS_UPDATE_DOCTOR_DEPARTMENT =
  "STATUS_UPDATE_DOCTOR_DEPARTMENT";

export const STATICS_DASHBOARD_DATA = "STATICS_DASHBOARD_DATA";
export const GRAPH_DASHBOARD_DATA = "GRAPH_DASHBOARD_DATA";
export const SYMPTOMS_STATUS_UPDATE = "SYMPTOMS_STATUS_UPDATE";

export const ALL_PATIENT_REPORTS = "ALL_PATIENT_REPORTS";
export const CREATE_ALL_PRESCRIPTION = "CREATE_ALL_PRESCRIPTION";

export const EDIT_ALL_PRESCRIPTION = "EDIT_ALL_PRESCRIPTION";
export const DELETE_PRESCRIPTIONS = "DELETE_PRESCRIPTIONS";
export const GET_ALL_TEMPLATE = "GET_ALL_TEMPLATE";
export const UPDATE_ALL_TEMPLATE = "UPDATE_ALL_TEMPLATE";
export const GIVE_ALL_RATING = "GIVE_ALL_RATING";
export const DOCTOR_REPORTS_EXPORT = "DOCTOR_REPORTS_EXPORT";
export const PATIENT_REPORTS_EXPORT = "PATIENT_REPORTS_EXPORT";
export const REPORTS_ALL_EXPORT = "REPORTS_ALL_EXPORT";

export const CREATE_CANCEL_REFUND_CRUD = "CREATE_CANCEL_REFUND_CRUD";

export const ALL_REFUND_APPOINTMENT = "ALL_REFUND_APPOINTMENT";

export const ALL_REFUND_STATUS_UPDATE = "ALL_REFUND_STATUS_UPDATE";

export const DOCTOR_PASSWORD_UPDATE = "DOCTOR_PASSWORD_UPDATE";
