// type

import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";

import icons from "helper/iconsHelper";

import {
  per_view_roles,
  per_role_edit,
  per_role_add,
} from "../helper/permissionsCheck";
// icons

// let permissionUser = localStorage.getItem("LoginData");
// let permissions = JSON.parse(permissionUser)?.permissions;

let permissionUser = localStorage.getItem("data");

let decryptedData = null;
if (permissionUser) {
  try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
      enc.Utf8
    );
    // ...
  } catch (error) {
    console.log(error, "er");
  }
}

const permissions = decryptedData
  ? JSON.parse(decryptedData)?.permissions
  : null;

const allowedPermissions =
  permissions && permissions.length !== 0
    ? permissions.map((permission) => permission?.permission)
    : [];

const permissionsAccessFunction = (brand) => {
  return (
    allowedPermissions &&
    allowedPermissions.length !== 0 &&
    allowedPermissions.some((permission) => permission.includes(brand))
  );
};
// ==============================|| MENU ITEMS - roles ||============================== //

// const flat = permissionsAccessFunction('company') ? {
const allroles = per_view_roles
  ? {
      id: "Roles",
      title: " All Roles",
      icon: icons.roles,
      type: "group",
      children: [
        per_view_roles
          ? {
              id: "all-roles",
              title: "All Roles",
              type: "item",
              url: "/roles/all-roles",
              icon: icons.roles,
              breadcrumbs: true,
            }
          : null,
      ].filter(Boolean),
    }
  : null;
export default allroles;
