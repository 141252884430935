// type

import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";

import icons from "helper/iconsHelper";

import {
  per_view_doctor_appointment,
  can_view_clients,
  create_Appointment_Book_Permissiosn,
} from "../helper/permissionsCheck";

// let permissionUser = localStorage.getItem("LoginData");
// let permissions = JSON.parse(permissionUser)?.permissions;

let permissionUser = localStorage.getItem("data");

let decryptedData = null;
if (permissionUser) {
  try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
      enc.Utf8
    );
    // ...
  } catch (error) {
    console.log(error, "er");
  }
}

const permissions = decryptedData
  ? JSON.parse(decryptedData)?.permissions
  : null;

const allowedPermissions =
  permissions && permissions.length !== 0
    ? permissions.map((permission) => permission?.permission)
    : [];

const permissionsAccessFunction = (brand) => {
  return (
    allowedPermissions &&
    allowedPermissions.length !== 0 &&
    allowedPermissions.some((permission) => permission.includes(brand))
  );
};
// ==============================|| MENU ITEMS - roles ||============================== //
const appointments =
  per_view_doctor_appointment || create_Appointment_Book_Permissiosn
    ? {
        id: "Appointments",
        title: "Appointments",
        icon: icons.calenders,
        type: "group",
        children: [
          per_view_doctor_appointment
            ? {
                id: "all-appointments",
                title: "View All Appointments",
                type: "item",
                url: "/appointments/all-appointments",
                icon: icons.calender,
                breadcrumbs: true,
              }
            : null,
          create_Appointment_Book_Permissiosn
            ? {
                id: "all-appointbooking",
                title: "Appointment Booking",
                type: "item",
                url: "/appointments/assign-appointments",
                icon: icons.calender,
                breadcrumbs: true,
              }
            : null,
        ].filter(Boolean),
      }
    : null;
export default appointments;
