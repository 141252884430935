
import {
    All_COUNTS
} from "../constant/ActionType";

export default function AllCountReducers(state = {
    allcount: [],

}, action) {
    switch (action.type) {
        case All_COUNTS:
            return {
                ...state,
                allcount: action?.data?.data,
                
            };

        default:
    }

    return state;
}
