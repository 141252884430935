import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  OutlinedInput,
} from "@mui/material";
// material-ui
import { Modal } from "antd";
import { PatientsDetail } from "store/action/index";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery, Box, Container, Toolbar } from "@mui/material";
import Drawer from "./Drawer";
import Header from "./Header";
import Footer from "./Footer";
import Breadcrumbs from "components/@extended/Breadcrumbs";
import { Button } from "@mui/material";
import navigation from "menu-items";
import { dispatch, useSelector } from "store";
import { openDrawer } from "store/reducers/menu";
import { DRAWER_WIDTH } from "config";
import { AES, enc } from "crypto-js";
import * as url from "../../store/constant/Endpoints";
import ThemeInput from "components/inputs/ThemeInput";
import CircularProgress from "@mui/material/CircularProgress";

const MainLayout = () => {
  const theme = useTheme();
  let handling = localStorage.getItem("isPending");
  const matchDownLG = useMediaQuery(theme.breakpoints.down("xl"));
  const { drawerOpen } = useSelector((state) => state.menu);
  const savedModalVisible = localStorage.getItem("modallVisible");
  const [modalVisible, setModalVisible] = useState(
    handling == 1 && !savedModalVisible ? true : false
  );
  const [loading, setLoading] = useState(false);
  const [emr_name, setEmrName] = useState("");
  const [emr_relation, setEmrRel] = useState("");
  const [emr_contact, setEmrContact] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [blood_pressure, setBloodPressure] = useState("");
  const [currency, setCurrency] = useState("");
  const [lang, setLang] = useState("");
  const [patient_id, setPatient_id] = useState(
    localStorage.getItem("PatientId")
  );
  useEffect(() => {
    localStorage.setItem("modallVisible", true);
  }, [modalVisible]);
  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
  }, [drawerOpen]);

  useEffect(() => {
    // console.log("ksjsks", modalVisible);
  }, [modalVisible]);

  let permissionUser = localStorage.getItem("data");

  let decryptedData = null;
  if (permissionUser) {
    try {
      decryptedData = AES.decrypt(
        permissionUser,
        url.encryptionSecret
      ).toString(enc.Utf8);
    } catch (error) {
      localStorage.clear();
      window.location.reload();
    }
  }

  const permissionsAssrray =
    decryptedData && JSON.parse(decryptedData)?.user_type;

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleSubmit = () => {
    let objj = {
      emr_name: emr_name,
      emr_relation: emr_relation,
      emr_contact: emr_contact,
      height: height,
      patient_id: patient_id,
      weight: weight,
      blood_pressure: blood_pressure,
      currency: currency,
      lang: lang,
    };
    setLoading(true);
    dispatch(PatientsDetail({ objj: objj, setLoading: setLoading }));
    closeModal();
  };

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Header open={open} handleDrawerToggle={handleDrawerToggle} />
        <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />

        <Box
          component="main"
          sx={{
            width: `calc(100% - ${DRAWER_WIDTH}px)`,
            flexGrow: 1,
            p: { xs: 2, sm: 3 },
          }}
        >
          <Toolbar sx={{ mt: "inherit" }} />
          <Container
            sx={{
              position: "relative",
              minHeight: "calc(100vh - 110px)",
              display: "flex",
              flexDirection: "column",
              maxWidth: "2000px !important",
            }}
          >
            <Breadcrumbs navigation={navigation} title />
            <Outlet />
            <Footer />
          </Container>
        </Box>
      </Box>
      <Modal
        title="Patients History"
        visible={modalVisible}
        onCancel={closeModal}
        footer={null}
        width={800}
      >
        <div className="row">
          <h4> Emergency Contact (Optional)</h4>
          <div className="col-md-4 my-2">
            <ThemeInput
              type="text"
              name="Add Emergency Name*"
              value={emr_name}
              onChange={(e) => setEmrName(e.target.value)}
            />
          </div>
          <div className="col-md-4 my-2">
            <ThemeInput
              type="text"
              name="Relation*"
              value={emr_relation}
              onChange={(e) => setEmrRel(e.target.value)}
            />
          </div>
          <div className="col-md-4 my-2">
            <ThemeInput
              type="number"
              name="Add Emergency Contact*"
              value={emr_contact}
              onChange={(e) => setEmrContact(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <h4>Health Metrics(Optional) </h4>
          <div className="col-md-4 my-2">
            <ThemeInput
              type="text"
              name="Add Height (Kg)*"
              value={height}
              onChange={(e) => setHeight(e.target.value)}
            />
          </div>
          <div className="col-md-4 my-2">
            <ThemeInput
              type="text"
              name="Add Weight (Ft)*"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
            />
          </div>
          <div className="col-md-4 my-2">
            <ThemeInput
              type="text"
              name="Blood Preassure*"
              value={blood_pressure}
              onChange={(e) => setBloodPressure(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <h4>Communication Prefernce (Optional)</h4>
          <div className="col-md-4 my-2 ">
            <InputLabel htmlFor="company">Communication*</InputLabel>
            <FormControl variant="outlined" fullWidth>
              <Select
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
                displayEmpty
                input={<OutlinedInput />}
              >
                <MenuItem value="Email">Email</MenuItem>
                <MenuItem value="Whatsapp">Whatsapp</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-md-4 my-2 ">
            <InputLabel htmlFor="company">Language*</InputLabel>
            <FormControl variant="outlined" fullWidth>
              <Select
                value={lang}
                onChange={(e) => setLang(e.target.value)}
                displayEmpty
                input={<OutlinedInput />}
              >
                <MenuItem value="English">English</MenuItem>
                <MenuItem value="Urdu">Urdu</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-md-12 my-2 ">
            <Button
              className="px-4"
              size="large"
              variant="contained"
              style={{
                backgroundColor: "#090446",
                padding: "10px",
                fontFamily: "poppins",
              }}
              onClick={handleSubmit}
            >
              {loading ? (
                <CircularProgress size={22} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MainLayout;
