import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";

const checkPermissions = (item_name) => {
  let permissionUser = localStorage.getItem("data");

  let decryptedData = null;
  if (permissionUser) {
    try {
      // Code that may throw an exception
      decryptedData = AES.decrypt(
        permissionUser,
        url.encryptionSecret
      ).toString(enc.Utf8);
      // ...
    } catch (error) {
      localStorage.clear();
      window.location.reload();
    }
  }
  let permissionsArray = [];

  try {
    permissionsArray = decryptedData && JSON.parse(decryptedData)?.permissions;
  } catch (e) {}
  const isAllow = permissionsArray?.includes(item_name);

  return isAllow;
};

// <---- property type permission ---------->
export const can_add_property_type = checkPermissions("add-property-type");
export const can_edit_property_type = checkPermissions("edit-property-type");
export const can_view_property_type = checkPermissions("view-property-type");
export const can_delete_property_type = checkPermissions(
  "delete-property-type"
);
// <---- property type permission ---------->
// <---- amenities permission ---------->
export const can_add_amenities = checkPermissions("add-amenities");
export const can_view_amenities = checkPermissions("view-amenities");
export const can_edit_amenities = checkPermissions("edit-amenities");
export const can_delete_amenities = checkPermissions("delete-amenities");
// <---- amenities  permission ---------->
// <---- Premises Size permission ---------->
export const can_add_premises_size = checkPermissions("add-premises-size");
export const can_delete_premises_size = checkPermissions(
  "delete-premises-size"
);
export const can_edit_premises_size = checkPermissions("edit-premises-size");
export const can_view_premises_size = checkPermissions("view-premises-size");
// <---- Premisies Size permission ---------->
// <---- Tax  permission ---------->
export const can_add_tax = checkPermissions("add-taxes");
export const can_view_tax = checkPermissions("view-taxes");
export const can_edit_tax = checkPermissions("edit-taxes");
export const can_delete_tax = checkPermissions("delete-taxes");
// <---- Tax  permission ---------->
// <---- Property  permission ---------->
export const can_add_property = checkPermissions("add-property");
export const can_delete_property = checkPermissions("delete-property");
export const can_view_property = checkPermissions("view-property");
export const can_edit_property = checkPermissions("edit-property");
// <---- Property  permission ---------->
// <---- flat  permission ---------->
export const can_add_flat = checkPermissions("add-flat");
export const can_delete_flat = checkPermissions("delete-flat");
export const can_edit_flat = checkPermissions("edit-flat");
export const can_view_flat = checkPermissions("view-flat");
// <---- flat  permission ---------->
// <---- assign premises  permission ---------->
export const can_add_assign_premises = checkPermissions("add-assign-premises");
export const can_edit_assign_premises = checkPermissions(
  "edit-assign-premises"
);
export const can_delete_assign_premises = checkPermissions(
  "delete-assign-premises"
);
export const can_view_assign_premises = checkPermissions(
  "view-assign-premises"
);
// <---- assign premises permission ---------->
// <---- clients  permission ---------->
export const can_add_clients = checkPermissions("add-clients");
export const can_delete_clients = checkPermissions("delete-clients");
export const can_view_clients = checkPermissions("view-clients");
export const can_edit_clients = checkPermissions("edit-clients");
// <---- clients  permission ---------->
// <---- payements  permission ---------->
export const can_add_payments = checkPermissions("add-payments");
export const can_view_payments = checkPermissions("view-payments");
export const can_delete_payments = checkPermissions("delete-payments");
export const can_edit_payments = checkPermissions("edit-payments");
// <---- payements  permission ---------->
// <---- roles permission ---------->
export const can_add_roles = checkPermissions("add-roles");
export const can_edit_roles = checkPermissions("edit-roles");
export const can_delete_roles = checkPermissions("delete-roles");
export const can_view_roles = checkPermissions("view-roles");
// <----roles  permission ---------->
// <----  permission ---------->
export const can_add_permissions = checkPermissions("add-permissions");
export const can_view_permission = checkPermissions("view-permissions");
export const can_edit_permission = checkPermissions("edit-permissions");
export const can_delete_permission = checkPermissions("delete-permissions");
// <----  permission ---------->
// <---- Users permission ---------->
export const can_add_users = checkPermissions("add-users");
export const can_view_users = checkPermissions("view-users");
export const can_edit_users = checkPermissions("edit-users");
export const can_delete_users = checkPermissions("delete-users");
// <---- Users permission ---------->

// <---- maintenance permission ---------->
export const can_add_maintenance = checkPermissions("create-maintenance");
export const can_view_maintenance = checkPermissions("view-maintenance");
export const can_edit_maintenance = checkPermissions("edit-maintenance");
export const can_delete_maintenance = checkPermissions("delete-maintenance");
// <---- maintenance permission ---------->

// <---- maintenance Type  permission ---------->
export const can_add_maintenance_type = checkPermissions(
  "create-maintenance-type"
);
export const can_delete_maintenance_type = checkPermissions(
  "delete-maintenance-type"
);
export const can_view_maintenance_type = checkPermissions(
  "view-maintenance-type"
);
export const can_edit_maintenance_type = checkPermissions(
  "edit-maintenance-type"
);
// <---- maintenance Type  permission ---------->

// <---- notifications permission ---------->
export const can_view_notifications = checkPermissions("view-notifications");
export const can_delete_notifications = checkPermissions(
  "delete-notifications"
);
// <---- notifications permission ---------->

// --------------------------------------------------------       ECLINIC       -------------------------------------------

export const per_view_doctor = checkPermissions("doctor-get");
export const per_view_doctor_category = checkPermissions("category-get");
export const per_view_doctor_appointment =
  checkPermissions("view-appointments");
export const per_view_slots = checkPermissions("slot-duration-view");
export const per_view_users = checkPermissions("user-get");
export const per_user_edit = checkPermissions("user-edit");
export const per_user_add = checkPermissions("user-add");

export const per_view_roles = checkPermissions("role-get");
export const per_role_edit = checkPermissions("role-edit");
export const per_role_add = checkPermissions("role-add");
export const per_doctor_qualifiation = checkPermissions("qualification-view");

export const per_permission_view = checkPermissions("permission-get");
export const per_payment_view = checkPermissions("payment-view");

export const per_patient_history_view = checkPermissions("patient-history-get");
export const per_patient_profile_handle_view =
  checkPermissions("patient-profile");

export const per_country_per_view = checkPermissions("country-get");
export const per_symptoms_view = checkPermissions("symptoms-get");

export const per_docor_report_view = checkPermissions("doctor-report");
export const per_patient_report_view = checkPermissions("patient-report");

export const per_all_patient_view = checkPermissions("patient-get");

export const per_docotor_Add = checkPermissions("doctor-add");
export const per_docotor_Editt = checkPermissions("doctor-edit");
export const per_docotor_Update_Passds = checkPermissions(
  "doctor-update-password"
);
export const per_docotor_remove = checkPermissions("doctor-remove");

export const per_docotor_C_Add = checkPermissions("category-add");
export const per_docotor_C_Editt = checkPermissions("category-update");
export const per_docotor_C_remove = checkPermissions("category-remove");

export const per_docotor_Q_Add = checkPermissions("qualification-add");
export const per_docotor_Q_Editt = checkPermissions("qualification-edit");
export const per_docotor_Q_remove = checkPermissions("qualification-remove");

export const per_Symptoms_Add = checkPermissions("symptoms-create");
export const per_Symptoms_Editt = checkPermissions("symptoms-edit");
export const per_Symptoms_remove = checkPermissions("symptoms-remove");

export const per_Patient_Editt = checkPermissions("patient-edit");
export const per_Patient_remove = checkPermissions("patient-delete");

export const per_patient_history_Add = checkPermissions(
  "patient-history-create"
);
export const per_patient_history_Editt = checkPermissions(
  "patient-history-update"
);
export const per_patient_history_remove = checkPermissions(
  "patient-history-remove"
);

export const per_Country_Add = checkPermissions("country-create");
export const per_Country_Editt = checkPermissions("country-edit");
export const per_Country_remove = checkPermissions("country-delete");

export const per_prescription_edit = checkPermissions("prescription-edit");
export const per_prescription_view = checkPermissions("prescription-get");
export const per_prescription_delete = checkPermissions("prescription-delete");

export const per_template_edit = checkPermissions("template-edit");
export const per_template_view = checkPermissions("template-get");

export const dashboarPer_Check = checkPermissions("dashboard");

export const master_Rpoert_Per = checkPermissions("master-report");

// export const all_Refund_view = checkPermissions("refund");

export const create_Appointment_Book_Permissiosn =
  checkPermissions("create-appointment");
export const Refunf_View_Per = checkPermissions("refund");
