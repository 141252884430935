import { GET_ALL_COUNTRY } from "../../constant/ActionType";

export default function AllCountries(
  state = {
    CountryData: [],
  },
  action
) {
  switch (action.type) {
    case GET_ALL_COUNTRY:
      return {
        ...state,
        CountryData: action?.data?.data,
      };

    default:
  }

  return state;
}
