import { CREATE_DOCTORS_QUALIFICATION } from "../../constant/ActionType";

export default function AllDoctorsQualifiction(
  state = {
    QualificationDoctorsData: [],
  },
  action
) {
  switch (action.type) {
    case CREATE_DOCTORS_QUALIFICATION:
      return {
        ...state,
        QualificationDoctorsData: action?.data,
      };

    default:
  }

  return state;
}
