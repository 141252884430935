// type

import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";
import icons from "helper/iconsHelper";
import { can_add_clients, per_view_slots ,per_patient_profile_handle_view} from "../helper/permissionsCheck";

let permissionUser = localStorage.getItem("data");

let decryptedData = null;
if (permissionUser) {
  try {
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
      enc.Utf8
    );
  } catch (error) {
    console.log(error, "er");
  }
}

const permissions = decryptedData
  ? JSON.parse(decryptedData)?.permissions
  : null;

const allowedPermissions =
  permissions && permissions.length !== 0
    ? permissions.map((permission) => permission?.permission)
    : [];

const permissionsAccessFunction = (brand) => {
  return (
    allowedPermissions &&
    allowedPermissions.length !== 0 &&
    allowedPermissions.some((permission) => permission.includes(brand))
  );
};

// const flat = permissionsAccessFunction('company') ? {
const patientprofile = per_patient_profile_handle_view
  ? {
      id: "Profile",
      title: "Profile/Patient",
      icon: icons.slots,
      type: "group",
      children: [
        true
          ? {
              id: "profile",
              title: "Profile",
              type: "item",
              url: "/profile/patient-profile",
              icon: icons.slots,
              breadcrumbs: true,
            }
          : null,
      ].filter(Boolean),
    }
  : null;
export default patientprofile;
