import { GRAPH_DASHBOARD_DATA } from "../../constant/ActionType";

export default function AllGraphDashboardRed(
  state = {
    alldataDashGraph: [],
  },
  action
) {
  switch (action.type) {
    case GRAPH_DASHBOARD_DATA:
      return {
        ...state,
        alldataDashGraph: action?.data?.data,
      };

    default:
  }

  return state;
}
