import { GET_ALL_PRESCRIPTION } from "../../constant/ActionType";

export default function GettPrescription(
  state = {
    allPresctiptionsss: [],
  },
  action
) {
  switch (action.type) {

    case GET_ALL_PRESCRIPTION:
        console.log(action?.data)
      return {
        ...state,
        allPresctiptionsss: action?.data?.data,
      };

    default:
  }

  return state;
}
