import { ALL_PATIENT_REPORTS } from "../../constant/ActionType";

export default function ReportsPatients(
  state = {
    reportssPatientss: [],
  },
  action
) {
  switch (action.type) {
    case ALL_PATIENT_REPORTS:
      return {
        ...state,
        reportssPatientss: action?.data?.data,
      };

    default:
  }

  return state;
}
