
import {
    DETAILS_DOCTORS
   } from "../../constant/ActionType";
   
   export default function DetailsAllDoctors(state = {
       AllDoctorsDetails: [],
   
   }, action) {
       switch (action.type) {
           case DETAILS_DOCTORS:
               return {
                   ...state,
                   AllDoctorsDetails: action?.data?.data,
               };
   
           default:
       }
   
       return state;
   }