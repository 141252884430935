// type

import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";

import icons from "helper/iconsHelper";

import {
  can_add_clients,
  can_edit_clients,
  master_Rpoert_Per,
  can_view_clients,
  per_view_users,
  per_patient_report_view,
} from "../helper/permissionsCheck";

let permissionUser = localStorage.getItem("data");

let decryptedData = null;
if (permissionUser) {
  try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
      enc.Utf8
    );
  } catch (error) {
    localStorage.clear();
    window.location.reload();
  }
}

const permissionsAssrray = decryptedData && JSON.parse(decryptedData);
// ==============================|| MENU ITEMS - roles ||============================== //

const patientwise =
  per_patient_report_view || master_Rpoert_Per
    ? {
        id: "patient-report",
        // title: "Reports",
        icon: icons.users,
        type: "group",
        children: [
          per_patient_report_view
            ? {
                id: "patient-report",
                title:
                  permissionsAssrray?.user_type === "patient"
                    ? "My Report"
                    : "Patient Report",
                type: "item",
                url: "/reports/patient-report",
                icon: icons.report,
                breadcrumbs: true,
              }
            : null,
            master_Rpoert_Per
            ? {
                id: "All reports",
                title: "Master Reports",
                type: "item",
                url: "/reports/master-report",
                icon: icons.report,
                breadcrumbs: true,
              }
            : null,
        ].filter(Boolean),
      }
    : null;
export default patientwise;
