import { ALL_APPOINTMENTS_REPORTS } from "../../constant/ActionType";

export default function ReportsAppoint(
  state = {
    reportssAppointments: [],
  },
  action
) {
  switch (action.type) {
    case ALL_APPOINTMENTS_REPORTS:
      return {
        ...state,
        reportssAppointments: action?.data?.data,
      };

    default:
  }

  return state;
}
