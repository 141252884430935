import { ALL_APPOINTMENTS } from "../../constant/ActionType";

export default function AppointmentsRedAll(
  state = {
    allapointments: [],
  },
  action
) {
  switch (action.type) {
    case ALL_APPOINTMENTS:
      return {
        ...state,
        allapointments: action?.data?.data,
      };

    default:
  }

  return state;
}
