import { APPOITNMENT_DATETIME_SLOT } from "../../constant/ActionType";

export default function AppointtSlotssss(
  state = {
    alldateTime: [],
  },
  action
) {
  switch (action.type) {
    case APPOITNMENT_DATETIME_SLOT:
      return {
        ...state,
        alldateTime: action?.data?.data,
      };

    default:
  }

  return state;
}
