import { ACTIVE_DOCTORS_DEACTIVE } from "../../constant/ActionType";

export default function AllDoctorsActiveDeactive(
  state = {
    ActiveDoctorsAll: [],
  },
  action
) {
  switch (action.type) {
    case ACTIVE_DOCTORS_DEACTIVE:
      return {
        ...state,
        ActiveDoctorsAll: action?.data?.data,
      };

    default:
  }

  return state;
}
