import {
    ASSIGN_PERMISSIONS
} from "../constant/ActionType";

export default function AssignPermisionsReducer(state = {
    childassignpermissions: [],
    statusCode: null,
}, action) {
    switch (action.type) {
        case ASSIGN_PERMISSIONS:
            return {
                ...state,
                childassignpermissions: action.data,
                    statusCode: action.status
            };

        default:
    }

    return state;
}