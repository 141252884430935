import { ALL_PATIENTS } from "../../constant/ActionType";

export default function PatietnsGetAllRed(
  state = {
    allpatients: [],
  },
  action
) {
  switch (action.type) {
    case ALL_PATIENTS:
      return {
        ...state,
        allpatients: action?.data?.data,
      };

    default:
  }

  return state;
}
