import { ALL_REFUND_APPOINTMENT } from "../../constant/ActionType";

export default function GetttRefunds(
  state = {
    allRefundssssssss: [],
  },
  action
) {
  switch (action.type) {

    case ALL_REFUND_APPOINTMENT:
        console.log(action?.data)
      return {
        ...state,
        allRefundssssssss: action?.data?.data,
      };

    default:
  }

  return state;
}
