import { ALL_GET_REPORTS } from "../../constant/ActionType";

export default function ReportsGetAll(
  state = {
    reportssAllGet: [],
  },
  action
) {
  switch (action.type) {
    case ALL_GET_REPORTS:
      return {
        ...state,
        reportssAllGet: action?.data?.data,
      };

    default:
  }

  return state;
}
