import { ALL_PATIENTS_HISTORY } from "../../constant/ActionType";

export default function PatietnsAllHistory(
  state = {
    allpatientshistory: [],
  },
  action
) {
  switch (action.type) {
    case ALL_PATIENTS_HISTORY:
      return {
        ...state,
        allpatientshistory: action?.data?.data,
      };

    default:
  }

  return state;
}
