// type

import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";

import icons from "helper/iconsHelper";

import {
  per_view_doctor_category,
  can_edit_clients,
  per_view_doctor,
  per_doctor_qualifiation,
} from "../helper/permissionsCheck";
// icons

// let permissionUser = localStorage.getItem("LoginData");
// let permissions = JSON.parse(permissionUser)?.permissions;

let permissionUser = localStorage.getItem("data");

let decryptedData = null;
if (permissionUser) {
  try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
      enc.Utf8
    );
    // ...
  } catch (error) {
    console.log(error, "er");
  }
}

const permissions = decryptedData
  ? JSON.parse(decryptedData)?.permissions
  : null;

const allowedPermissions =
  permissions && permissions.length !== 0
    ? permissions.map((permission) => permission?.permission)
    : [];

const permissionsAccessFunction = (brand) => {
  return (
    allowedPermissions &&
    allowedPermissions.length !== 0 &&
    allowedPermissions.some((permission) => permission.includes(brand))
  );
};
// ==============================|| MENU ITEMS - roles ||============================== //

// const flat = permissionsAccessFunction('company') ? {
const doctors =
  per_view_doctor_category || per_view_doctor || per_doctor_qualifiation
    ? {
        id: "Docotrs",
        title: "Doctors",
        icon: icons.doctors,
        type: "group",
        children: [
          per_view_doctor
            ? {
                id: "all-docotrs",
                title: "View All Doctors",
                type: "item",
                url: "/doctors/all-doctors",
                icon: icons.doctors,
                breadcrumbs: true,
              }
            : null,
          per_view_doctor_category
            ? {
                id: "docotrs-category",
                title: "Doctors Department",
                type: "item",
                url: "/doctors/doctors-category",
                icon: icons.category,
                breadcrumbs: true,
              }
            : null,
          per_doctor_qualifiation
            ? {
                id: "docotrs-qualification",
                title: "Doctors Qualification",
                type: "item",
                url: "/doctors/doctors-qualification",
                icon: icons.qualification,
                breadcrumbs: true,
              }
            : null,
        ].filter(Boolean),
      }
    : null;
export default doctors;
