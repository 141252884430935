// type

import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";

import icons from "helper/iconsHelper";

import {
  can_add_clients,
  per_view_slots,
  per_all_patient_view,
  per_patient_history_view,
} from "../helper/permissionsCheck";

let permissionUser = localStorage.getItem("data");

let decryptedData = null;
if (permissionUser) {
  try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
      enc.Utf8
    );
  } catch (error) {
    localStorage.clear();
    window.location.reload();
  }
}

const permissionsAssrray = decryptedData && JSON.parse(decryptedData);
// ==============================|| MENU ITEMS - roles ||============================== //

// const flat = permissionsAccessFunction('company') ? {
const patients =
  per_all_patient_view || per_patient_history_view
    ? {
        id: "Patients",
        title: "Patients",
        icon: icons.patients,
        type: "group",
        children: [
          per_all_patient_view
            ? {
                id: "all-patients",
                title: "View All Patients",
                type: "item",
                url: "/patient/all-patients",
                icon: icons.patients,
                breadcrumbs: true,
              }
            : null,
          per_patient_history_view
            ? {
                id: "patients-history",
                title:
                  permissionsAssrray?.user_type === "patient"
                    ? "My History"
                    : "View Patients History",
                type: "item",
                url: "/patient/patients-history",
                icon: icons.history,
                breadcrumbs: true,
              }
            : null,
        ].filter(Boolean),
      }
    : null;
export default patients;
