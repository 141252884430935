import { ACTIVE_COUNTRY_DEACTIVE } from "../../constant/ActionType";

export default function ActiveCountries(
  state = {
    CountryActive: [],
  },
  action
) {
  switch (action.type) {
    case ACTIVE_COUNTRY_DEACTIVE:
      return {
        ...state,
        CountryActive: action?.data,
      };

    default:
  }

  return state;
}
