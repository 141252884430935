// type

import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";

import icons from "helper/iconsHelper";

import { can_add_clients, per_payment_view } from "../helper/permissionsCheck";

// let permissionUser = localStorage.getItem("LoginData");
// let permissions = JSON.parse(permissionUser)?.permissions;

let permissionUser = localStorage.getItem("data");

let decryptedData = null;
if (permissionUser) {
  try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
      enc.Utf8
    );
    // ...
  } catch (error) {
    console.log(error, "er");
  }
}

const permissions = decryptedData
  ? JSON.parse(decryptedData)?.permissions
  : null;

const allowedPermissions =
  permissions && permissions.length !== 0
    ? permissions.map((permission) => permission?.permission)
    : [];

const permissionsAccessFunction = (brand) => {
  return (
    allowedPermissions &&
    allowedPermissions.length !== 0 &&
    allowedPermissions.some((permission) => permission.includes(brand))
  );
};
// ==============================|| MENU ITEMS - roles ||============================== //

// const flat = permissionsAccessFunction('company') ? {
const payments = per_payment_view
  ? {
      id: "Payments",
      title: "Payments",
      icon: icons.payments,
      type: "group",
      children: [
        per_payment_view
          ? {
              id: "all-payments",
              title: "Payments",
              type: "item",
              url: "/payments/all-payments",
              icon: icons.payments,
              breadcrumbs: true,
            }
          : null,
      ].filter(Boolean),
    }
  : null;
export default payments;
