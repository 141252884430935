// type
import { Home3, HomeTrendUp } from "iconsax-react";
import { LuLayoutDashboard } from "react-icons/lu";
import {
  per_template_view,
  dashboarPer_Check,
} from "../helper/permissionsCheck";
const icons = {
  navigation: Home3,
  dashboard: LuLayoutDashboard,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = dashboarPer_Check
  ? {
      id: "group-dashboard",
      // title: 'Navigation',
      icon: icons.navigation,
      type: "group",
      children: [
        {
          id: "dashboard",
          title: "Dashboard",
          type: "item",
          url: "/dashboard",
          icon: icons.dashboard,
          breadcrumbs: false,
        },
      ],
    }
  : null;

export default dashboard;
