import { useRoutes } from "react-router-dom";
import React, { useState, useEffect } from "react";

// project-imports
import ComponentsRoutes from "./ComponentsRoutes";
import MainRoutes from "./MainRoutes";
import { useCookies } from "react-cookie";
import * as url from "../store/constant/Endpoints";

const CryptoJS = require("crypto-js");

// ==============================|| ROUTES RENDER ||============================== //

export default function ThemeRoutes() {
  let user = localStorage.getItem("token");
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const [cookiesnew, setCookienww, removeCookienewww] = useCookies([
    "eclinic_session",
  ]);
  useEffect(() => {
    const userDataFromCookie = cookies.user;
    const logoutDataFromCookie=cookies.user_logout
    console.log('logoutDataFromCookie',logoutDataFromCookie)
    if(logoutDataFromCookie==='logout'){
      document.cookie =
        "user_logout=; path=/; domain=.eclinicasia.com; expires=Thu, 01 Jan 1970 00:00:00 GMT; Secure; SameSite=None;";
        removeCookie("user_logout");
        localStorage.clear();
      window.location.reload();

    }
    if (userDataFromCookie) {
      const encryptedToken = CryptoJS.AES.encrypt(
        userDataFromCookie.token,
        url.encryptionSecret
      ).toString();

      const encryptedLoginData = CryptoJS.AES.encrypt(
        JSON.stringify(userDataFromCookie),
        url.encryptionSecret
      ).toString();
      localStorage.setItem("token", encryptedToken);
      localStorage.setItem("data", encryptedLoginData);
      localStorage.setItem("Id", userDataFromCookie.id);
      localStorage.setItem("isPending", userDataFromCookie.is_pending);

      if (userDataFromCookie.user.user_type == "patient") {
        localStorage.setItem("PatientId", userDataFromCookie.user.patient_id);
      }
      document.cookie =
        "user=; path=/; domain=.eclinicasia.com; expires=Thu, 01 Jan 1970 00:00:00 GMT; Secure; SameSite=None;";

      removeCookie("user");
      removeCookienewww("eclinic_session");

      window.location.reload();
    }
  }, []);

  return useRoutes([
    user && user !== "" && user !== null ? MainRoutes : ComponentsRoutes,
  ]);
}
