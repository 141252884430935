import { GET_ALL_SYMPTOMS } from "../../constant/ActionType";

export default function AllSymptoms(
  state = {
    allSymptoms: [],
  },
  action
) {
  switch (action.type) {

    case GET_ALL_SYMPTOMS:
        console.log(action?.data)
      return {
        ...state,
        allSymptoms: action?.data?.data,
      };

    default:
  }

  return state;
}
