import { ALL_DOCTORS_CATEGORY } from "../../constant/ActionType";

export default function AllDoctorsCategory(
  state = {
    CategoryDoctorsData: [],
  },
  action
) {
  switch (action.type) {
    case ALL_DOCTORS_CATEGORY:
      return {
        ...state,
        CategoryDoctorsData: action?.data?.data,
      };

    default:
  }

  return state;
}
