import { GET_ALL_SLOTS } from "../../constant/ActionType";

export default function SlotsAllMainRed(
  state = {
    allslots: [],
  },
  action
) {
  switch (action.type) {
    case GET_ALL_SLOTS:
      return {
        ...state,
        allslots: action?.data?.data,
      };

    default:
  }

  return state;
}
